<template>
    <div class="staffTaget">
      <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>业绩目标分析</a-breadcrumb-item>
        <a-breadcrumb-item>课消业绩分析</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="main-box">
        <div class="clearfix table-tools">
            <div class="search" style="padding-bottom:10px;">
            <a-form layout='inline'>
                    <!-- <a-form-item>
                      <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item> -->

                    <!-- <a-form-item>
                      <a-radio-group :value="paramsData.filter_column" @change="handleColButChange">
                        <a-radio-button value="money">
                            金额
                        </a-radio-button>
                        <a-radio-button value="count">
                            单数
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item> -->

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-select :value="year" @change="handleChangeTime">
                            <a-select-option v-for="(d, index) of yearList" :key="index" :value="d">{{ d }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>课程科目</span>
                        </template>
                        <a-select allowClear placeholder="请选择课程科目" :filterOption="filterOption" @change="handleSubjectChange" style="width: 210px">
                        <a-select-option v-for="(d, index) of courseCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-tooltip>
                    </a-form-item>
                <a-form-item>    
                  <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
                </a-form-item>
            </a-form>
            </div>
        </div>
        <div style="padding:20px" class="dashboard bk-group">
            <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <goal :loading='loading' titles="课消业绩分析" :item ='leadsData' year='' :showLineList="showLineList" />
                    </a-col>
            </a-row>

            <div style="background:#fff;">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                :columns="columns" :dataSource="list" :scroll="{ x: RelativeWidth, y:500}">
                  <template v-for="(it,j) in name_list" :slot="it.filed_name" slot-scope="text, record , index">
                    <div :key="j">
                      <span >{{text}}</span>
                      <br/>
                      <span style="color:rgba(69, 90, 100, 0.4)">({{record[`${it.filed_name}F`]}}%)</span>
                    </div>
                  </template>
                </a-table>
            </div>
            <!-- <div style="margin-top:15px;background:#fff">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="month"
                    :columns="achiement_columns" :dataSource="achiement_list">
                </a-table>
            </div> -->
        </div>
      </div>
    </div>
</template>
<script>
const achiement_columns = [
  { title: '时间', dataIndex: 'month', key: '1' ,align:'right'},
  { title: '目标金额(元)', dataIndex: 'achiement', key: '2',align:'right' },
  { title: '课消金额(元)', dataIndex: 'receivables', key: '3',align:'right' },
  { title: '完成度%', dataIndex: 'rate', key: '4' ,align:'right'},
]
import ranges from "@/common/mixins/ranges"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from "moment"
import tableMixins from '@/common/mixins/table'
import goal from '../goal/goal'
export default {
    provide() {
      return {
        parent: this
      }
    },
    data(){
        return{
            loading:false,
            exportLoading:false,
            columns:[],
            pageParams:{
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            paramsData:{
              year: Number(moment().format('YYYY')),
              obj_id:'',
              course_cate_id:'',
              filter_type:'month',
              filter_column:'money'
            },
            list:[],
            incomeDate:[],
            studios:[],
            filedLength:0,
            courseCategorys:[],
            achiement_columns,
            achiement_list:[],
            name_list:[],
            leadsData:{columns:['时间','目标金额','课消金额','完成度'],rows:[]},
            year: Number(moment().format('YYYY')),
            yearList:[],
            showLineList:['课消金额','完成度']
        }
    },
    mixins: [ tableMixins,ranges ],
    components: { moment , DatePicker , goal},
    created(){
      let defaultYear = 2015
      let nowYear = Number(moment().format('YYYY'))
      let yearList = [nowYear]
      for(let i=0; i <= nowYear-defaultYear; i++){
          nowYear-=1
          yearList.push(nowYear)
      }
      this.yearList = yearList
      this.getStudio() 
      this.getCourseCategory()
    },
    computed: {
        RelativeWidth() {
            let width = 800
            if(this.filedLength >10){
              width = 800 + ( this.filedLength - 10 ) * 130
            }
            return width
        }
  },
    methods:{
        moment,
        filterOption(input, option) {
          return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        },
        notBeforeYear(date){
          const today = new Date()
          return date >= today
        },
        async getList(obj) {
            this.loading = true
            this.searchParams.search.year = this.year || Number(moment().format('YYYY'))
            let res = await this.$store.dispatch('analysisGoalStudioCourseRevenueAction',{search:this.paramsData})
            let list = res.data.course_revenue_achiement_list
            list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total) : item.total
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                    item[`${it.filed_name}F`] = it.filed_precent
                })
            })
            let week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', scopedSlots: { customRender: 'name' }, fixed: 'left',ellipsis: true},
                { title: '合计', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '占比', width:'80px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
            ]
            if(list[0]){
              this.name_list = list[0].filed
              list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',scopedSlots: { customRender: item.filed_name }}
                  week.push(obj)
              })
            }
            if(list[0]){
              this.filedLength = list[0].filed.length
            }else{
              this.filedLength = 0
            }
            this.columns = week
            this.list = list
            
            this.achiement_list = res.data.course_revenue_achiement
            let row = []
            if(this.achiement_list.length > 0){
                this.achiement_list.forEach(item=>{
                    let obj = {
                        时间:item.month,
                        目标金额:item.achiement,
                        课消金额:item.receivables,
                        完成度:item.rate/100
                    }
                    row.push(obj)
                })
            }
            this.leadsData.rows = row


            this.loading = false
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        async getCourseCategory() {
          let res = await this.$store.dispatch('searchCourseTypeAction', {})
          this.courseCategorys = res.data
        },
        handleChangeTime(e){
          this.year = e
          this.paramsData.year = e
        },
        handleChange(value){
        //   this.paramsData.studio_id = value
          this.paramsData.obj_id = value
        },
        handleButChange(e){
          this.paramsData.filter_type = e.target.value
          this.toScreen();
        },
        handleSubjectChange(value){
          this.paramsData.course_cate_id = value;
        },
        handleColButChange(e){
          this.paramsData.filter_column = e.target.value
          this.toScreen();
        },
        toScreen(){
          let obj = {
            search:this.paramsData
          }
          this.getList(obj);
        },
        // 获取一个月有几周
        getWeeks(year, month) {
            var d = new Date();
            // 该月第一天
            d.setFullYear(year, month-1, 1);
            var w1 = d.getDay();
            if (w1 == 0) w1 = 7;
            // 该月天数
            d.setFullYear(year, month, 0);
            var dd = d.getDate();
            // 第一个周一
            let d1;
            if (w1 != 1) d1 = 7 - w1 + 2;
            else d1 = 1;
            let week_count = Math.ceil((dd-d1+1)/7);
            return week_count;
        },
        filterNum(val){
          val = val.toString().replace(/\$|\,/g,'');
          if(isNaN(val)) {
            val = "0";  
          } 
          let sign = (val == (val = Math.abs(val)));
          val = Math.floor(val*100+0.50000000001);
          let cents = val%100;
          val = Math.floor(val/100).toString();
          if(cents<10) {
            cents = "0" + cents
          }
          for (var i = 0; i < Math.floor((val.length-(1+i))/3); i++) {
              val = val.substring(0,val.length-(4*i+3))+',' + val.substring(val.length-(4*i+3));
          }

          return (((sign)?'':'-') + val + '.' + cents);
        }
    }
}
</script>
<style lang="scss">
  .staffTaget{
    .ant-table-row{
      td:nth-child(2){
        background: rgb(229, 244, 254);
      }
      td:nth-child(3){
        background: rgb(229, 244, 254);
      }
      td:nth-child(4){
        background: rgb(229, 244, 254);
      }
    }
    .table-name{
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>